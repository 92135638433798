// GoogleSignIn.js
import React from 'react'
import { useDispatch } from 'react-redux'
import { auth } from './firebase'
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect } from 'firebase/auth'

import { setUser } from './redux/userSlice'

const GoogleSignIn = () => {
  const dispatch = useDispatch()

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider()
    if (window.innerWidth <= 768) {
      signInWithRedirect(auth, provider)
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          const user = result.user
          dispatch(setUser(user))
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <button className="btn btn-info" onClick={signInWithGoogle}>
      Sign in with Google
    </button>
  )
}

export default GoogleSignIn
