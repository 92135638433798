// import 'firebase/compat/auth'
// import 'firebase/compat/firestore'

import React, { useEffect, useRef, useState } from 'react'
import { cardToggle, handleGoogleRedirect, handleGoogleSignIn, isSet } from '../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'

import Board from './Board'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import PlayerList from './PlayerList'
import ProgressBar from 'react-bootstrap/ProgressBar'
// import Modal from './Modal'
import Signout from './Signout'
import Spinner from 'react-bootstrap/Spinner'
import firebase from 'firebase/compat/app'
// import firestore from '../firestore'
import { isEmpty, set } from 'lodash'
import { updateNickname } from '../redux/userSlice'
import useLocalStorage from '../hooks/useLocalStorage'
import _ from 'lodash'
import GoogleSignIn from '../GoogleSignIn'
import { db } from '../fire-setup'
// import firebase from 'firebase'
import {
  collection,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  onSnapshot,
} from '@firebase/firestore'

const generateFakeUid = () => {
  return Math.random()
    .toString(36)
    .substring(2, 11)
}

function Guest(props) {
  // const userReducer = useSelector((state) => state.user)
  // const { user, loading: userLoading } = userReducer
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.user.loading)
  const user = useSelector((state) => state.user.user)

  const [state, setFullState] = useState({
    popupVisible: false,
    setFound: false,
    displayAnimation: false,
    animatedSet: [],
    declarer: '',
    deck: [],
    board: [],
    selected: [],
    pending: null,
    gameStarted: false,
  })
  const [userRefusedSignIn, setUserRefusedSignin] = useState(false)
  const [userJoined, setUserJoined] = useState(false)
  const myName = useSelector((state) => state.user.user?.nickname || 'anon')

  const [userUid, setUserUid] = useLocalStorage('userUid', null)

  const myFire = useRef({})
  const firebaseRefs = myFire.current

  const currentState = useRef(state)
  currentState.current = state

  const setState = (update) => {
    setFullState({
      ...currentState.current,
      ...update,
    })
  }

  const handleJoinGame = (e) => {
    e.preventDefault()
    const nameInput = user.nickname
    if (isEmpty(nameInput)) {
      return
    }
    const fakeUid = generateFakeUid()
    setUserUid(fakeUid)

    const action = {
      type: 'join',
      payload: { name: nameInput, uid: user.uid || fakeUid },
    }

    console.log(action)
    sendAction(action)
    setUserJoined(true)
  }

  const processUpdate = (doc) => {
    const updatedState = { ...doc.data() }
    const { selected: mySelected } = currentState.current
    if (isEmpty(updatedState)) {
      return
    }
    // console.log('Updating', updatedState)
    // Don't mess with selected cards unless necessary
    const newSelected =
      mySelected.length < 3 && isEmpty(updatedState.declarer) ? mySelected : updatedState.selected
    console.log('New selected', newSelected)
    setState({
      ...updatedState,
      selected: newSelected,
      popupVisible: false,
    })
  }

  const sendAction = (action) => {
    console.log('Creating on', firebaseRefs.actions)
    addDoc(firebaseRefs.actions, {
      ...action,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    }).then(function(docRef) {
      console.log(docRef)
      if (action.type === 'found') {
        const docId = docRef.id
        console.log('Document written with ID: ', docId)
        setState({
          pending: docId,
        })
        // TODO: Trigger message if action isnt processed in reasonable time
      }
    })
  }

  useEffect(() => {
    const { gameName } = props.match.params
    const GamesCollectionRef = collection(db, 'games')

    const GameDocRef = doc(db, 'games', gameName)

    firebaseRefs.game = onSnapshot(GameDocRef, (doc) => {
      processUpdate(doc)
    })

    const unsubscribeGame = firebaseRefs.game

    firebaseRefs.actions = collection(db, 'games', gameName, 'actions')

    return () => {
      unsubscribeGame()
    }

    // return function cleanup() {
    //   if (firebaseRefs.game) {
    //     unsubGames()
    //   }
    //   if (firebaseRefs.actions) {
    //     unsubActions()
    //   }
    // }
  }, [props.match.params, processUpdate])

  const resetLocalSelected = () => {
    const { declarer, selected } = currentState.current
    if (isEmpty(declarer) && selected.length === 3 && !isSet(selected)) {
      setState({
        selected: [],
      })
    }
  }

  const handleCardClick = (card) => {
    const { declarer, selected } = currentState.current
    if (declarer) {
      return
    }
    const newSelected = cardToggle(card, selected)
    if (newSelected.length > 3) {
      return
    }
    const newState = {}
    if (newSelected.length === 3) {
      if (isSet(newSelected)) {
        const action = {
          type: 'found',
          payload: { selected: newSelected, name: myName },
        }
        console.log('Found set, sending...')
        sendAction(action)
        newState.popupVisible = true
      } else {
        console.log('Bad set selected!')
        window.setTimeout(resetLocalSelected, 1000)
      }
    }

    setState({
      ...newState,
      selected: newSelected,
    })
  }

  const { board, deck, selected, declarer, players, popupVisible } = state

  if (loading) {
    return 'Loading profile...'
  }

  if (isEmpty(user) && !userRefusedSignIn) {
    return (
      <div className="container mt-4">
        <p>To join a game, sign in with your Google account.</p>
        <p>
          <div className="row">
            <div className="col-12 col-md-3">
              <GoogleSignIn />
              {/* <button onClick={handleGoogleRedirect} className="btn btn-info">
                Sign in
              </button> */}
            </div>
            <div className="col-12 col-md-3">
              <button
                onClick={() => {
                  setUserUid(generateFakeUid())
                  setUserRefusedSignin(true)
                }}
                className="btn btn-info"
              >
                Skip Signin
              </button>
            </div>
          </div>
        </p>
        <p>
          <Link to="/lobby">Back</Link>
        </p>
      </div>
    )
  }

  if (!userJoined) {
    return (
      <div className="container">
        <Signout />

        <h4 className="mb-3">Choose your nickname:</h4>
        <form onSubmit={handleJoinGame}>
          <div className="col-12 col-md-4">
            <input
              autoFocus
              type="text"
              placeholder="your name"
              value={user.nickname || ''}
              onChange={(e) => {
                dispatch(updateNickname(e.target.value))
                // setMyName(e.target.value)
                // window.localStorage.setItem('nickname', e.target.value)
              }}
            />
          </div>
          <div className="col-12 col-md-4">
            <input className="btn btn-primary mt-3 ml-md-3" type="submit" value="Join" />
          </div>
        </form>
      </div>
    )
  }

  const { setFound, gameOver, gameStarted } = currentState.current

  if (!gameStarted) {
    return <PlayerList players={players} isHost={false} />
  }

  return (
    <React.Fragment>
      <Modal show={state.pending && popupVisible}>
        <Modal.Header>
          <Modal.Title>Submitting action...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">SET!</p>
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        </Modal.Body>
      </Modal>
      <Board
        board={board}
        deck={deck}
        selected={selected}
        declarer={declarer}
        handleCardClick={handleCardClick}
        // handleDeclare={this.handleDeclare}
        players={players}
        setFound={setFound}
        gameOver={gameOver}
        // syncing={this.state.syncing}
        myName={myName}
        gameMode="versus"
      />
    </React.Fragment>
  )
}

export default Guest
