import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import React, { useEffect, useState } from 'react'
import { setUser } from '../redux/userSlice'

import Routes from './Routes'
import axios from 'axios'
import firebase from 'firebase/compat/app'
import { useDispatch, useSelector } from 'react-redux'
// import { auth } from '../firebase'

// import { auth } from '../firebase'
import { auth } from '../fire-setup'

const serializeUser = (user, nickname) => {
  return {
    displayName: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    photoURL: user.photoURL,
    isAnonymous: user.isAnonymous,
    uid: user.uid,
    providerData: user.providerData,
    nickname,
  }
}

function App(props) {
  const user = useSelector((state) => state.user.user)

  const dispatch = useDispatch()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log('user', user)
      if (user) {
        console.log('user is real')
        // const nickname = window.localStorage.getItem('nickname') || user.displayName.split(' ')[0]
        dispatch(setUser(serializeUser(user, 'William')))
      } else {
        dispatch(setUser({}))
      }
    })

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [dispatch])

  // console.log('selected user', user)

  // useEffect(() => {
  //   auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       const nickname = window.localStorage.getItem('nickname') || user.displayName.split(' ')[0]
  //       const myUser = {
  //         displayName: user.displayName,
  //         email: user.email,
  //         emailVerified: user.emailVerified,
  //         photoURL: user.photoURL,
  //         isAnonymous: user.isAnonymous,
  //         uid: user.uid,
  //         providerData: user.providerData,
  //         nickname,
  //       }
  //       dispatch(updateUser({ loading: false, user: myUser }))
  //       axios
  //         .get(`/api/user/${user.uid}`)
  //         .then((result) => {
  //           console.log('User is registered')
  //         })
  //         .catch((err) => {
  //           if (err.response && err.response.status === 404) {
  //             console.log('User not registered')
  //             axios
  //               .post('/api/user', {
  //                 uid: user.uid,
  //                 info: myUser,
  //               })
  //               .then(() => {
  //                 console.log('User registered successfully')
  //               })
  //               .catch((err) => {
  //                 console.log('Error registering user', err)
  //               })
  //           } else {
  //             console.log('An error occurred trying to GET user info')
  //           }
  //         })
  //     } else {
  //       console.log('Not signed in')
  //       dispatch(updateUser({ loading: false, user: {} }))
  //     }
  //   })
  // }, [])

  return <Routes />
}

export default App
