const firebaseConfig = {
  apiKey: 'AIzaSyCLA_RFXrPvBdN_vrApCUlj28a82ownuzg',
  authDomain: 'fire-set.firebaseapp.com',
  databaseURL: 'https://fire-set.firebaseio.com',
  projectId: 'fire-set',
  storageBucket: 'fire-set.appspot.com',
  messagingSenderId: '958559518798',
  appId: '1:958559518798:web:ec451bbfb4ac03f30ec31f',
  measurementId: 'G-FCHHM3FEZE',
}

export default firebaseConfig
