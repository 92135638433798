import './styles/bts/bootstrap.scss'
import './styles/bts/index.scss'

import firebase from 'firebase/compat/app'
// Required for side-effects
import 'firebase/firestore'

import App from './components/App'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import store from './store'
// import GameViewer from './GameViewer'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)
